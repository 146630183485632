import {useQuery, gql, useReactiveVar} from '@apollo/client';
import {appLanguage} from "../reactive-variables";

const GET_PARTNER_ONE = gql`
    query GetPartnerOne($partner_id_or_slug_eq: String!) {
        partner_one(where: { partner_id_or_slug_eq: $partner_id_or_slug_eq }) {
            id
            slug
            facebook
            instagram
            link
            linkedin
            mail
            phone_number_1
            phone_number_2
            tiktok
            youtube
            created_at
            updated_at
            title
            description
            address
            image_url
            logo_url
            offers {
                id
                slug
                link
                created_at
                title
                description
                image_url
                mobile_url
            }
            categories {
                id
                slug
                title
            }
        }
    }
`;


export const usePartnerOne = ({ partner_id_or_slug_eq = null } = {}) => {
    const language = useReactiveVar(appLanguage);

    const { data, loading, error, fetchMore } = useQuery(GET_PARTNER_ONE, {
        skip: !partner_id_or_slug_eq,
        variables: { partner_id_or_slug_eq },
        context: {
            headers: {
                "accept-language": language
            }
        }
    });

    if (error) {
        console.error("GraphQL Error:", error);
    }

    console.log(data,'datadatadata')
    return { data: data?.partner_one || null, loading, error, fetchMore };
};


