import { useQuery, gql, useReactiveVar } from '@apollo/client';
import { appLanguage } from "../reactive-variables";

const GET_PARTNER_CATEGORY = gql`
    query GetPartnerCategory{
        partner_category {
            id
            slug
            title
        }
    }
`

export const usePartnerCategory = () => {
    const language = useReactiveVar(appLanguage)
    const { data, loading,  } = useQuery(GET_PARTNER_CATEGORY, {
        context: {
            headers: {
                "accept-language": language
            }
        }
    });
    return { list: data?.partner_category, loading }
};
