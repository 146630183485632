import React, {useEffect, useState} from "react";
import  './copy-block.scss';
import { CopyIcon } from "../../../assets/images";

import {CopyToClipboard} from "react-copy-to-clipboard";
import { useTranslations } from "../../../graphql/query.hooks/translation.hook";

export default function CopyBlock(props) {
    const {copyUrl} = props
    const [copied, setCopied] = useState(false);
    const translations = useTranslations()

    useEffect(() => {
        setTimeout(() => {
            setCopied(false)
        },3000)
    }, [copied]);

    return <div className={'copy_wrapper'}>
        <CopyToClipboard text={copyUrl}>
            <div className={`copy_block ${copied ? 'copied':''}`}
                 onClick={e => {
                     e.stopPropagation()
                    !copied && setCopied(true)
                 }}>
                <CopyIcon/>
                <span>{translations?.fitness_page_modal_copy}</span>
            </div>
        </CopyToClipboard>
    </div>
}
