import './packages.scss'
import { useState, useEffect } from "react";
import { Pagination, Tabs } from "antd";
import { useTranslations } from "../../graphql/query.hooks/translation.hook";
import { PackageTypes } from "../../utils/constanats";
import { usePackages } from "../../graphql/query.hooks/package.hook";
import PackageCard from "../../components/uiElements/cards/packageCard/PackageCard";


function Packages () {
    const translations = useTranslations()
    const [activeTab, setActiveTab] = useState(PackageTypes.Package)
    const { data: packages } = usePackages(activeTab)
    const onChange = (key) => {
        setActiveTab(key)
    };
    useEffect(() => {
        window.scrollTo({
            top: 0,
        });
    }, [])

    const items = [
        {
            key: PackageTypes.Package,
            label: `${translations?.homepage_membership_title || ""}`,
        },
        {
            key: PackageTypes.Paid,
            label: `${translations?.homepage_addition_title || ""}`,
        }
    ];


    return <div className={'packages-page-wrapper'}>
        <div className={'packages-header'}>
            <div className={'top'}/>
            <div className={'content'}>
                <div className={'inner'}>
                    <h1 className={'page-title'}>
                        {translations?.packages_page_title || ""}
                    </h1>
                    <div className={'sub-title'}>
                        {translations?.packages_page_subtitle || ""}
                    </div>
                </div>
            </div>
        </div>

        <div className={'page_content'}>
            <Tabs value={activeTab} items={items} onChange={onChange}/>
            {packages?.length ? <div className={'packages_wrapper'}>
                {
                    packages?.map(item => {
                        const data = {
                            id: item.id,
                            name: item.name,
                            title: item.title,
                            information: item.web_information,
                            description: item.web_description,
                            details: item.web_details,
                            duration_options: item.duration_options,
                        }
                        return <div className={'package-card-wrapper'} key={item?.id}>
                                <PackageCard data={data} translations={translations}/>
                            </div>

                    })
                }
            </div>: <div>

                </div>}
        </div>

    </div>
}

export default Packages
