import React, {useEffect, useRef, useState} from 'react';
import './partnersDetail.scss'
import {
    CloseMenuIcon,
    FaceBookIcon,
    ImgMainResponsive,
    InstagramPIcon,
    LinkedinPIcon,
    TiktokPIcon,
    YoutubeIcon
} from "../../../assets/images";
import {usePartnerOne} from "../../../graphql/query.hooks/partnersOne.hook";
import {Link, useParams} from "react-router-dom";
import {useTranslations} from "../../../graphql/query.hooks/translation.hook";
import {GetUrlWithLocal} from "../../../utils/getUrlWithLocal";

export default function PartnersDetail(props) {
    const translations = useTranslations()
    const [openPhones,setOpenPhones] = useState(false)
    const dropDownRef = useRef();
    const params = useParams();
    const { data: partnerById, loading: loadingPartners } = usePartnerOne({
        partner_id_or_slug_eq:params?.id
    })

    useEffect(() => {
        if (openPhones) {
            window.addEventListener('click', onClickedOutSide, true)
            window.addEventListener('scroll', onClickedOutSide, true)
        } else {
            window.removeEventListener('click', onClickedOutSide, false)
            window.removeEventListener('scroll', onClickedOutSide, false)
        }
        return () => {
            window.removeEventListener('scroll', onClickedOutSide, false)
        }
    }, [openPhones]);

    function onClickedOutSide(event) {
        const path = event.composedPath ? event.composedPath() : event.path;
        if (!path?.includes(dropDownRef?.current)) {
            setOpenPhones(false)
        }
    }

    return <div className="partnersDetail">
        <div className={'top'}/>
        <div className={'header-block'}>
            <div className={'content'}>
                <Link to={GetUrlWithLocal('/partners')}>{translations?.partners_detail_page_link} ›</Link>
                <p>{partnerById?.title}</p>
            </div>
        </div>

        <div className={'partnersDetail_wrapper'}>
            <div className={'partnersDetail_left'}>
                <div className={'partnersDetail_img'}>
                    <img src={partnerById?.image_url} alt=""/>
                </div>
                <div className={'partnersDetail_title'}>
                    {partnerById?.title}
                </div>
                <div className={'partnersDetail_sub'}>
                    {partnerById?.description}
                </div>
                <div className={'partnersDetail_socials'}>
                    {
                        partnerById?.facebook && <a href={partnerById?.facebook} target={'_blank'}><FaceBookIcon/></a>
                    }
                    {
                        partnerById?.instagram && <a href={partnerById?.instagram} target={'_blank'}><InstagramPIcon/></a>
                    }
                    {
                        partnerById?.linkedin && <a href={partnerById?.linkedin} target={'_blank'}><LinkedinPIcon/></a>
                    }
                    {
                        partnerById?.tiktok && <a href={partnerById?.tiktok} target={'_blank'}><TiktokPIcon/></a>
                    }
                    {
                        partnerById?.youtube && <a href={partnerById?.youtube}><YoutubeIcon/></a>
                    }
                </div>
                <div className={'partnersDetail_addresses'}>
                    {
                        partnerById?.link && <a href={partnerById?.link} target={'_blank'}>{partnerById?.link}</a>
                    }
                    {
                        partnerById?.mail && <a href={partnerById?.mail} target={'_blank'}>{partnerById?.mail}</a>
                    }
                    {
                        partnerById?.address && <span>{partnerById?.address}</span>
                    }
                </div>
                {
                    partnerById?.phone_number_1 || partnerById?.phone_number_2 ? <div className={'partnersDetail_call'}>
                        <div className={'partnersDetail_phone'} onClick={() => setOpenPhones(!openPhones)}
                            //@ts-ignore
                             ref={dropDownRef}>
                            {translations?.partners_phone_btn}
                        </div>
                        {
                            openPhones && <div className={'phones'}>
                                <div className={'close_phones'}>
                                    <CloseMenuIcon/>
                                </div>
                                {
                                    partnerById?.phone_number_1 &&
                                    <a href={`tel:${partnerById?.phone_number_1}`} target={'_blank'}
                                       className={'phone_item'}>
                                        {partnerById?.phone_number_1}
                                    </a>
                                }
                                {
                                    partnerById?.phone_number_2 &&
                                    <a href={`tel:${partnerById?.phone_number_2}`} target={'_blank'}
                                       className={'phone_item'}>
                                        {partnerById?.phone_number_2}
                                    </a>
                                }
                            </div>
                        }
                    </div> : ''
                }
            </div>
            <div className={'partnersDetail_right'}>
                {
                    partnerById?.offers?.length && partnerById?.offers?.map((offer, index) => {
                        return <div className={'partnersDetail_img_item'}>
                            <img src={offer?.image_url} alt=""/>
                            {
                                offer?.title || offer?.description ? <div className={'partnersDetail_content'}>
                                    <div className={'partnersDetail_content_info'}>
                                        <div className={'sale'}>
                                            {offer?.title}
                                        </div>
                                        <div className={'description'}  dangerouslySetInnerHTML={{__html: offer?.description}}/>
                                    </div>
                                    {
                                        offer?.link &&
                                        <a href={offer?.link} target={'_blank'} rel="noreferrer" className={'link_btn'}>
                                            {translations?.partners_detail_more_btn}
                                        </a>
                                    }
                                </div> : ''
                            }
                        </div>
                    })
                }
            </div>
        </div>

    </div>
}