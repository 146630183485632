import './partners.scss'
import {ImgMainResponsive} from "../../assets/images";
import {Tabs} from "antd";
import {useEffect, useState} from "react";
import {useTranslations} from "../../graphql/query.hooks/translation.hook";
import PartnersCard from "../../components/uiElements/cards/partnersCard/PartnersCard";
import {useCategory} from "../../graphql/query.hooks/category.hook";
import {usePartnerCategory} from "../../graphql/query.hooks/partnerCategory.hook";
import {useEvents} from "../../graphql/query.hooks/event.hook";
import {usePartners} from "../../graphql/query.hooks/partners.hook";


export default function Partners(){
    const translations = useTranslations()
    const [activeActiveTab, setActiveActiveTab] = useState(null)
    const [articlePage, setArticlePage] = useState(1);
    const partners_category = usePartnerCategory()

    useEffect(() => {
        window.scrollTo({
            top: 0,
        });
    }, [])

    const articleCategories = [
        {
            key: null,
            label:`${translations?.event_article_label_text}`,
        },
    ];
    partners_category?.list?.forEach(c => {
        articleCategories?.push({
            key: c.id,
            label: c.title,
        })
    })

    const { data: partnersData, loading: loadingPartners } = usePartners({
        category_id_in:activeActiveTab
    });
    function onChangeArticleActiveTab (activeTab) {
        setActiveActiveTab(activeTab)
        setArticlePage(1)
    }

    return <div className={'partners-wrapper'}>
        <div className={'header-block'}>
            <div className={'top'}/>
            <div className={'content'}>
                <div className={'inner'}>
                    <h2 className={'header-title'}>{translations?.partners_page_title}</h2>
                    <div className={'header-description'}>
                        {translations?.partners_page_description}
                    </div>
                </div>
            </div>
        </div>

        <div className={'partners_list_wrapper'}>
            {
                articleCategories?.length > 1 &&   <Tabs value={activeActiveTab} items={articleCategories} onChange={onChangeArticleActiveTab}/>
            }
            <div className={`partners_list`}>
                {
                    !!partnersData?.length && partnersData?.map((item, index) => {
                        return <div className={'partners_list-card'} key={index}>
                        <PartnersCard partners={item} translations={translations}/>
                        </div>
                    })
                }
            </div>
        </div>
    </div>
}