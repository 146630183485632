import './partnersCard.scss'
import {Link} from "react-router-dom";
import {MoreBtnIcon} from "../../../../assets/images";
import {GetUrlWithLocal} from "../../../../utils/getUrlWithLocal";


export default function PartnersCard(props){
    const {partners,translations} = props

    return <Link to={GetUrlWithLocal(`/partners/detail/${partners?.id}`)}  className='partnersCard'>
        <div className={'partnersCard_img'}>
            <img src={partners?.image_url} alt=""/>
        </div>
        <div className={'info_block'}>
            <div className={'info_title'}>
                {partners?.title}
            </div>
            <div className={'info_description'}>
                <span>{translations?.partners_card_service}</span> {partners?.description}
            </div>
            <Link to={GetUrlWithLocal(`/partners/detail/${partners?.id}`)} className={'more-btn'}>
                {translations?.partners_card_more} <MoreBtnIcon/>
            </Link>
        </div>
    </Link>
}