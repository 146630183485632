import { useQuery, gql, useReactiveVar } from '@apollo/client';
import { appLanguage } from "../reactive-variables";

const GET_ABOUT = gql`
    query GetAbout{
        about{
            id
            title
            description
            logo_url
            video_url
            video_title
            video_description
            thumbnail_url
            block_title
            presentation_url
            blocks{
                id
                title
                description
                file_url
            }
        }
    }
`

export const useAbout = () => {
    const language = useReactiveVar(appLanguage)
    const { data, loading} = useQuery(GET_ABOUT, {
        context: {
            headers: {
                "accept-language": language
            }
        }
    });
    return { data: data?.about, loading }
};
