import { useQuery, gql, useReactiveVar } from '@apollo/client';
import { appLanguage } from "../reactive-variables";

const GET_PARTNERS = gql`
    query GetPartners($category_id_in: [String!]) {
        partner(where: { category_id_in: $category_id_in }) {  
            id
            title
            slug
            image_url
            description
            categories {
                id
                slug
                title
            }
        }
    }
`;

export const usePartners = ({ category_id_in = null } = {}) => {
    const language = useReactiveVar(appLanguage);

    const { data, loading, error, fetchMore } = useQuery(GET_PARTNERS, {
        variables: { category_id_in: category_id_in ? [category_id_in] : null},
        context: {
            headers: {
                "accept-language": language
            }
        }
    });

    if (error) {
        console.error("GraphQL Error:", error);
    }

    console.log(data,'data')
    return { data: data?.partner, loading, fetchMore };
};
