import './trainerModal.scss'
import {Modal} from "antd";
import {ModalCloseIcon} from "../../../../assets/images";
import CopyBlock from "../../copy-block/CopyBlock";

function TrainerModal(props){
     const {closeModal, visible, trainerData, copyUrl} = props

     // console.log(trainerData,'trainerData')
     return trainerData && <Modal
         visible={visible}
         wrapClassName={'trainer-modal'}
         transitionName={""}
         centered={true}
         closeIcon={<ModalCloseIcon/>}
         maskClosable={true}
         onCancel={closeModal}>
          <div className={'trainer-modal-wrapper'}>
               <div className={'trainer-modal-img-wrapper'}>
                    <div className={'trainer-modal-img'}>
                         <img src={trainerData?.image_url} alt=""/>
                    </div>
                    {/*<div className={'web-wrapper'}>*/}
                    {/*     <CopyBlock copyUrl={copyUrl}/>*/}
                    {/*</div>*/}
               </div>
               <div className={'trainer-modal-info'}>
                    <div className={'trainer-modal-header'}>
                         <div className={'trainer-modal-title'}>
                              {trainerData?.name}
                         </div>
                         <div className={'trainer-modal-position'}>
                              {trainerData?.profession}
                         </div>
                    </div>
                    <div className={'trainer-modal-bio'} dangerouslySetInnerHTML={{__html: trainerData?.bio}}/>
                    <div className={`mobile-wrapper`}>
                         <CopyBlock copyUrl={copyUrl}/>
                    </div>
               </div>
          </div>
     </Modal>
}export default TrainerModal
